<template>
  <div>
    <r-app
      :sidebar-menu-data="{
        menus: menus,
        bottomMenus: bottomMenus,
        defaultIcon: 'brightness-1',
        logoUrl: '/images/icons/favicon-96x96.png',
        settings: {
          showedMenus: {
            firstLevel: 6,
            secondLevel: 2,
          },
        },
      }"
      :is-loading-sidebar-menu="isLoadingSidebarMenu"
    >
      <template #header-right>
        <r-notification
          :notification-data="
            activeNotificationTab === 1 ? notification : notificationRequest
          "
          :is-active="isNotificationActive"
          :key="1"
          :is-loading="isLoadingNotification"
          v-model="activeNotificationTab"
          :tabs="['Notification', 'Request']"
          @showAllNotifications="() => (isActive = true)"
          @infiniteHandler="onInfiniteScroll"
        >
          <template #header-right>
            <b-button class="is-command" @click="markAllAsRead">
              Mark All as Read
            </b-button>
          </template>
          <template slot="item" slot-scope="{ item }">
            <contract-expiration-body
              :item="item"
              @readNotif="readNotif"
              v-if="item.moduleType == 'contract_expiration'"
            ></contract-expiration-body>
            <time-off-body
              :item="item"
              @readNotif="readNotif"
              v-else-if="item.moduleType == 'request_timeoff'"
            ></time-off-body>
            <change-schedule-body
              :item="item"
              @readNotif="readNotif"
              v-else-if="item.moduleType == 'request_change_schedule'"
            ></change-schedule-body>
            <overtime-body
              :item="item"
              @readNotif="readNotif"
              v-else-if="item.moduleType == 'request_overtime'"
            ></overtime-body>
            <reimburse-body
              :item="item"
              @readNotif="readNotif"
              v-else-if="item.moduleType == 'request_reimbursement'"
            ></reimburse-body>
            <activity-adjustment-body
              :item="item"
              @readNotif="readNotif"
              v-else-if="
                item.moduleType == 'request_attendance_activity_adjustment'
              "
            ></activity-adjustment-body>
            <attendance-adjusment-body
              :item="item"
              @readNotif="readNotif"
              v-else-if="item.moduleType == 'request_attendance_adjustment'"
            ></attendance-adjusment-body>
            <office-permission-body
              :item="item"
              @readNotif="readNotif"
              v-else-if="item.moduleType == 'request_wfo'"
            ></office-permission-body>
            <default-body
              :item="item"
              @readNotif="readNotif"
              v-else
            ></default-body>
          </template>
        </r-notification>
        <r-profile
          v-if="currentUser != null"
          :is-online="isOnline"
          :userData="{
            name: currentUser.fullName,
            role: formatPositionName(currentUser),
            profilePicture: determineProfilePicture(currentUser),
          }"
          :isOnline="currentUser.workingStatus === 'online'"
          :user-page-link="'/settings/personal-info'"
          :settings-page-link="'/settings/personal-info'"
        >
          <!-- <b-dropdown-item aria-role="listitem">Status page</b-dropdown-item>
          <b-dropdown-item aria-role="listitem">Help Center</b-dropdown-item> -->
          <b-dropdown-item separator></b-dropdown-item>
          <b-dropdown-item
            v-if="!isLoggingOut"
            aria-role="listitem"
            tag="b-button"
            @click="logout()"
          >
            Logout
          </b-dropdown-item>

          <b-dropdown-item v-else aria-role="listitem" tag="b-button">
            Logging out...
          </b-dropdown-item>
        </r-profile>
      </template>
      <template #default>
        <router-view />
      </template>
      <template #footer>
        <div class="level">
          <div class="level-left"></div>
          <div class="level-right">Base Project Roketin v0.4.0</div>
        </div>
      </template>
    </r-app>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { mapCategoryNotif } from '../constant'
import testMenus from '../_test/menus'
import moment from 'moment'
import ContractExpirationBody from '../components/Notifications/ContractExpirationBody'
import DefaultBody from '../components/Notifications/DefaultBody.vue'
import TimeOffBody from '../components/Notifications/TimeOffBody.vue'
import ChangeScheduleBody from '../components/Notifications/ChangeScheduleBody.vue'
import OvertimeBody from '../components/Notifications/OvertimeBody.vue'
import ReimburseBody from '../components/Notifications/ReimburseBody.vue'
import ActivityAdjustmentBody from '../components/Notifications/ActivityAdjustmentBody.vue'
import AttendanceAdjusmentBody from '../components/Notifications/AttendanceAdjustmentBody.vue'
import OfficePermissionBody from '../components/Notifications/OfficePermissionBody.vue'

export default {
  watch: {
    async activeNotificationTab(value) {
      if (value === 1) {
        this.activeNotificationTab = value
        // load data tab pertama
        await this.loadNotifications()
      } else if (value === 2) {
        this.activeNotificationTab = value

        await this.loadNotificationRequest('request')
        // load data tab kedua
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'user/getUserInfo',
      notification: 'notifications/getNotifications',
      notificationRequest: 'notifications/getNotificationRequest',
    }),
    bottomMenus() {
      let defaultMenus = [
        {
          title: 'FAQ',
          path: 'faq',
          icon: 'help-circle',
          options: {
            attrs: {
              target: '_blank',
            },
          },
        },
        // hide menu switch company as described in RISE-1110
        // https://astronauts.atlassian.net/browse/RISE-1110?atlOrigin=eyJpIjoiNWMwNWRkNmI3MGI5NDUzYzhhN2IxZjg2ZjEwZmFkNGUiLCJwIjoiaiJ9
        // {
        //   title: 'Switch Company',
        //   url: process.env.VUE_APP_SPACEID_BASE_URL + '/select/company',
        //   icon: 'briefcase',
        //   options: {
        //     // to open new tab, uncomment this code
        //     // attrs: {
        //     //   target: '_blank',
        //     // },
        //   },
        // },
        {
          title: 'Settings',
          basePath: '/settings',
          icon: 'cog',
          children: [
            {
              title: 'Calendar Settings',
              path: '/calendar-settings',
              roles: ['admin', 'companyOwner', 'hr'],
            },
            {
              title: 'Personal Info',
              basePath: '/personal-info',
              children: [
                {
                  title: 'Personal Information',
                  path: '/',
                },
                {
                  title: 'Edit Personal Information',
                  path: '/edit',
                },
                {
                  title: 'Edit Timezone',
                  path: '/edit-timezone',
                },
              ],
            },
            {
              title: 'Request Management',
              basePath: '/request-management',
              roles: ['admin', 'companyOwner', 'hr'],
              children: [
                {
                  title: 'Time Off Category',
                  path: '/time-off-category',
                },
                {
                  title: 'Reimbursement Category',
                  path: '/reimbursement-category',
                },
              ],
            },
            {
              title: 'Activity Category',
              path: '/activity-category',
              roles: ['admin', 'companyOwner', 'hr'],
            },
            {
              title: 'Office',
              path: 'office',
              roles: ['admin', 'companyOwner', 'hr'],
            },
            {
              title: 'Final Approval',
              path: 'final-approval',
              roles: ['admin', 'companyOwner', 'hr'],
            },
            {
              title: 'Change Personal Info',
              basePath: 'list-request-personal-info',
              roles: ['admin', 'companyOwner', 'hr'],
              children: [
                {
                  title: 'Change Personal Info Management',
                  path: '/',
                  roles: ['admin', 'companyOwner', 'hr'],
                },
              ],
            },
            {
              title: 'Allowance Setting',
              path: 'allowance-setting',
              roles: ['admin', 'companyOwner', 'hr'],
            },
            {
              title: 'General Setting',
              path: 'general-setting',
              roles: ['admin', 'companyOwner', 'hr'],
            },
            {
              title: 'Divisions',
              path: 'divisions',
              roles: ['admin', 'companyOwner', 'hr'],
            },
            {
              title: 'Quote Setting',
              path: 'quote-setting',
              roles: ['admin', 'companyOwner', 'hr'],
            },
            {
              title: 'Change Password',
              path: 'change-password',
            },
            {
              title: 'Release Note',
              path: 'release-note',
              roles: ['admin', 'companyOwner', 'hr', 'assigneeReleaseNote'],
            },
          ],
        },
      ]

      if (process.env.NODE_ENV === 'development') {
        let menuArray = [testMenus]
        return (menuArray = menuArray.concat(defaultMenus))
      }

      return defaultMenus
    },
    menus() {
      return [...this.listMenus]
    },
  },
  components: {
    ContractExpirationBody,
    DefaultBody,
    TimeOffBody,
    ChangeScheduleBody,
    OvertimeBody,
    ReimburseBody,
    ActivityAdjustmentBody,
    AttendanceAdjusmentBody,
    OfficePermissionBody,
  },
  data() {
    return {
      page: 0,
      lastPage: 0,
      total: 0,
      perPage: 10,
      isLoggingOut: false,
      isLoadUser: false,
      isNotificationActive: false,
      isOnline: false,
      isLoadingSidebarMenu: true,
      isLoadingNotification: false,
      activeNotificationTab: 1,
      mapCategoryNotif: mapCategoryNotif,
      notificationData: [
        // {
        //   message: 'Ricky Septian asks to change his profile picture',
        //   date: 'Aug 19, 2020',
        //   time: '08:18',
        // },
        // {
        //   message: 'Ricky Septian asks to change his profile picture',
        //   date: 'Aug 19, 2020',
        //   time: '08:18',
        // },
      ],
      listMenus: [
        {
          path: '/rise',
          title: 'Rise',
          icon: 'orbit',
        },
        {
          path: '/dashboard',
          title: 'Dashboard',
          icon: 'view-dashboard',
          roles: ['admin', 'companyOwner', 'hr'],
        },
        {
          basePath: '/attendance',
          title: 'Attendance',
          icon: 'calendar-blank',
          hideSecondLevelMenu: true,
          children: [
            {
              path: '/',
              title: 'All Employee',
              roles: ['admin', 'companyOwner', 'hr', 'internalAdmin'],
            },
            {
              path: '/my-team',
              title: 'My Teams',
            },
            {
              path: '/my-attendance',
              title: 'My Attendance',
            },
          ],
        },
        {
          title: 'Request',
          basePath: '/request',
          icon: 'star-three-points',
          children: [
            {
              basePath: '/overtime',
              title: 'Overtime',
              children: [
                {
                  path: '/',
                  title: 'Personal',
                },
                {
                  path: '/management',
                  title: 'Management',
                },
              ],
            },
            {
              basePath: '/time-off',
              title: 'Time Off',
              children: [
                {
                  path: '/',
                  title: 'Personal',
                },
                {
                  path: '/management',
                  title: 'Management',
                },
              ],
            },
            {
              basePath: '/change-schedule',
              title: 'Change Schedule',
              children: [
                {
                  path: '/',
                  title: 'Personal',
                },
                {
                  path: '/management',
                  title: 'Management',
                },
              ],
            },
            {
              basePath: '/reimbursement',
              title: 'Reimbursement',
              children: [
                {
                  path: '/',
                  title: 'Personal',
                },
                {
                  path: '/management',
                  title: 'Management',
                },
              ],
            },
            {
              basePath: '/attendance-adjustment',
              title: 'Attendance Adjustment',
              children: [
                {
                  path: '/',
                  title: 'Personal',
                },
                {
                  path: '/management',
                  title: 'Management',
                },
              ],
            },
            {
              basePath: '/activity-adjustment',
              title: 'Activity Adjustment',
              children: [
                {
                  path: '/',
                  title: 'Personal',
                },
                {
                  path: '/management',
                  title: 'Management',
                },
              ],
            },
            {
              basePath: '/office-permission',
              title: 'Office Permission',
              children: [
                {
                  path: '/',
                  title: 'Personal',
                },
                {
                  path: '/management',
                  title: 'Management',
                },
              ],
            },
          ],
        },
        {
          path: '/people',
          title: 'People',
          icon: 'account-multiple',
        },
        {
          title: 'Employment',
          basePath: '/employment',
          icon: 'account',
          hideSecondLevelMenu: true,
          roles: ['admin', 'companyOwner', 'hr', 'internalAdmin'],
          children: [
            {
              path: '/general',
              title: 'General',
            },
            {
              path: '/contract',
              title: 'Contract',
            },
            {
              path: '/archive',
              title: 'Archive',
            },
          ],
        },
        {
          title: 'Announcement',
          basePath: '/announcement',
          icon: 'bullhorn',
          hideSecondLevelMenu: true,
        },
        {
          title: 'Company',
          basePath: '/companies',
          icon: 'briefcase',
          hideSecondLevelMenu: true,
          roles: ['internalAdmin'],
          children: [
            {
              path: '/',
              title: 'User Management',
            },
            {
              path: '/list-company',
              title: 'List Company',
            },
          ],
        },
        {
          title: 'Voucher',
          basePath: '/voucher',
          icon: 'ticket-confirmation',
          hideSecondLevelMenu: true,
          roles: ['internalAdmin'],
          children: [
            {
              path: '/',
              name: 'Manage Voucher',
            },
          ],
        },
        {
          title: 'Payment',
          basePath: '/list-payment',
          icon: 'cash',
          hideSecondLevelMenu: true,
          roles: ['internalAdmin'],
        },
        {
          title: 'Subscription',
          basePath: '/subscription',
          icon: 'credit-card',
          roles: ['companyOwner'],
          children: [
            {
              path: '/invoice',
              title: 'Data Invoice',
              icon: 'credit-card',
            },
            {
              path: '/refund',
              title: 'Data Refund',
              icon: 'file-table',
            },
          ],
        },
        {
          title: 'Packages',
          basePath: '/packages',
          icon: 'archive',
          hideSecondLevelMenu: true,
          roles: ['internalAdmin'],
          children: [
            {
              path: '/monthly',
              title: 'Monthly',
            },
            {
              path: '/annual',
              title: 'Annual',
            },
          ],
        },
      ],
      adminMenus: [],
    }
  },
  methods: {
    ...mapActions({
      actionLoadCurrentUser: 'user/loadUserFromToken',
      actionLoadNotification: 'notifications/fetchNotifications',
      actionUpdateReadNotif: 'notifications/updateRead',
      actionUpdateReadAllNotif: 'notifications/readAll',
    }),
    ...mapMutations({
      setAuth: 'auth/setAuth',
    }),
    async readNotif(id) {
      try {
        await this.actionUpdateReadNotif(id)
      } catch (err) {
        console.log(err)
      }
    },
    async markAllAsRead() {
      try {
        await this.actionUpdateReadAllNotif()
      } catch (err) {
        console.log(err)
      }
    },

    determineProfilePicture(user) {
      if (user?.profilePictureUrl) {
        return user?.profilePictureUrl
      }

      if (user?.gender === 'M') {
        return '/images/default-profile-picture-male.png'
      }

      if (user?.gender === 'F') {
        return '/images/default-profile-picture-female.png'
      }

      return '/images/default-profile-picture.png'
    },
    formatPositionName(user) {
      if (user.position) {
        return user.position.positionName
      }
      return '-'
    },
    async logout() {
      this.isLoggingOut = true
      try {
        await this.$store.dispatch('auth/logout')
      } catch (err) {
        console.log(err)
      }
      this.isLoggingOut = false
    },
    async loadCurrentUser() {
      this.isLoadUser = true
      try {
        let response = await this.actionLoadCurrentUser()
        if (this.currentUser.isCompanyOwner) {
          this.$_SET_ROLE('companyOwner')
        } else if (this.currentUser.isAdmin) {
          this.$_SET_ROLE('admin')
        } else if (this.currentUser.isAssigneeReleaseNote) {
          this.$_SET_ROLE('assigneeReleaseNote')
        } else if (this.currentUser.isAdminInternal === true) {
          this.$_SET_ROLE('internalAdmin')
        } else {
          this.$_SET_ROLE('user')
        }
        this.isLoadingSidebarMenu = false

        this.setAuth(response.data)
      } catch (err) {
        console.log(err)
      }
      this.isLoadUser = true
    },
    apendRouteAdmin() {
      // attendance
      this.listMenus[1].children.push({
        path: '/all',
        title: 'All Employee Attendance',
      })
    },
    formattime(date, format) {
      return moment.utc(date).local().format(format)
    },
    async onInfiniteScroll($event) {
      if (this.page < this.lastPage) {
        // logic kalo masih ada page selanjutnya
        $event.loading()
        await this.loadNotifications()
        $event.loaded()
      } else {
        $event.complete() // jika sudah tidak ada page lagi, buat stop infinite scroll nya
      }
    },
    async loadNotifications() {
      this.isLoadingNotification = true
      try {
        let response = await this.actionLoadNotification({
          page: ++this.page,
          row: this.perPage,
        })

        this.lastPage = response.data.meta.lastPage
        this.total = response.data.meta.total
      } catch (err) {
        console.log(err)
      }

      this.isLoadingNotification = false
    },

    async loadNotificationRequest(logType) {
      this.isLoadingNotification = true
      try {
        let response = await this.actionLoadNotification({
          page: this.page,
          row: this.perPage,
          log_type: logType,
        })

        this.lastPage = response.data.meta.lastPage
        this.total = response.data.meta.total
      } catch (err) {
        console.log(err)
      }

      this.isLoadingNotification = false
    },
  },
  async mounted() {
    await this.loadCurrentUser()
    await this.loadNotifications()
    // if(this.currentUser.isAdmin){
    //   this.apendRouteAdmin()
    // }
  },
}
</script>
